import { css } from '@onbeam/styled-system/css';
import { useActivePopups } from '../../state/application/hooks';
import PopupItem from './PopupItem';
import React from 'react';
import { Toaster } from 'sonner';

export default function Popups() {
  // get all popups
  const activePopups = useActivePopups();

  return (
    <>
      <Toaster
        position="bottom-right"
        offset={8}
        gap={8}
        toastOptions={{
          unstyled: true,
          className: css({ w: '[100%]' }),
        }}
      />
      {activePopups.map((item) => (
        <PopupItem
          key={item.key}
          content={item.content}
          removeAfterMs={item.removeAfterMs}
        />
      ))}
    </>
  );
}
