import { Link } from '@onbeam/ui';
import { footerStyle } from './Footer.styles';
import { text } from '@onbeam/styled-system/patterns';

export const Footer = () => (
  <footer className={footerStyle}>
    <Link
      className={text({ style: 'sm', color: 'mono.0' })}
      href="https://support.onbeam.com/hc/en-us/categories/14553016517533-Beam-Swap"
      target="_blank"
      rel="noopener noreferrer"
    >
      need help? get support
    </Link>
  </footer>
);
