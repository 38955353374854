import { DownIcon } from '@onbeam/icons';
import { Field } from '../../state/swap/actions';
import { isAddress, shortenAddress } from '../../utils';
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  warningSeverity,
} from '../../utils/prices';
import { AutoColumn } from '../Column';
import CurrencyLogo from '../CurrencyLogo';
import { RowBetween, RowFixed } from '../Row';
import { TruncatedText, SwapShowAcceptChanges } from './styleds';
import { Trade, TradeType } from '@beamswap/sdk';
import { useMemo } from 'react';
import { AlertTriangle } from 'react-feather';
import { Button } from '@onbeam/ui';
import { text } from '@onbeam/styled-system/patterns';

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade: Trade;
  allowedSlippage: number;
  recipient: string | null;
  showAcceptChanges: boolean;
  onAcceptChanges: () => void;
}) {
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage],
  );
  const { priceImpactWithoutFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade],
  );
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  return (
    <AutoColumn $gap={'md'} style={{ marginTop: '20px' }}>
      <RowBetween $align="flex-end">
        <RowFixed gap={'0px'}>
          <CurrencyLogo
            currency={trade.inputAmount.currency}
            size={'24px'}
            style={{ marginRight: '12px' }}
          />
          <TruncatedText
            className={text({
              style: 'xl',
              color:
                showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT
                  ? 'purple.200'
                  : undefined,
            })}
          >
            {trade.inputAmount.toSignificant(6)}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap={'0px'}>
          <p className={text({ style: 'xl', ml: '3' })}>
            {trade.inputAmount.currency.symbol}
          </p>
        </RowFixed>
      </RowBetween>
      <RowFixed>
        <DownIcon />
      </RowFixed>
      <RowBetween $align="flex-end">
        <RowFixed gap={'0px'}>
          <CurrencyLogo
            currency={trade.outputAmount.currency}
            size={'24px'}
            style={{ marginRight: '12px' }}
          />
          <TruncatedText
            className={text({
              style: 'xl',
              color:
                priceImpactSeverity > 2
                  ? 'red.400'
                  : showAcceptChanges &&
                      trade.tradeType === TradeType.EXACT_INPUT
                    ? 'purple.200'
                    : undefined,
            })}
          >
            {trade.outputAmount.toSignificant(6)}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap={'0px'}>
          <p className={text({ style: 'xl', ml: '3' })}>
            {trade.outputAmount.currency.symbol}
          </p>
        </RowFixed>
      </RowBetween>
      {showAcceptChanges ? (
        <SwapShowAcceptChanges $justify="flex-start" $gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <AlertTriangle
                size={20}
                style={{ marginRight: '8px', minWidth: 24 }}
              />
              <p className={text({ color: 'purple.200' })}> price updated</p>
            </RowFixed>
            <Button onClick={onAcceptChanges}>accept</Button>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn
        $justify="flex-start"
        $gap="sm"
        style={{ padding: '12px 0 0 0px' }}
      >
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <p className={text({ style: 'md', textAlign: 'left', w: 'full' })}>
            {'output is estimated. you will receive at least '}
            <b>
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)}{' '}
              {trade.outputAmount.currency.symbol}
            </b>
            {' or the transaction will revert.'}
          </p>
        ) : (
          <p className={text({ style: 'md', textAlign: 'left', w: 'full' })}>
            {'input is estimated. you will sell at most '}
            <b>
              {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)}{' '}
              {trade.inputAmount.currency.symbol}
            </b>
            {' or the transaction will revert.'}
          </p>
        )}
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn
          $justify="flex-start"
          $gap="sm"
          style={{ padding: '12px 0 0 0px' }}
        >
          <p>
            output will be sent to{' '}
            <strong title={recipient}>
              {isAddress(recipient) ? shortenAddress(recipient) : recipient}
            </strong>
          </p>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  );
}
