import { AppDispatch } from '../../state';
import { acceptListUpdate } from '../../state/lists/actions';

import listVersionLabel from '../../utils/listVersionLabel';
import { diffTokenLists, TokenList } from '@uniswap/token-lists';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@onbeam/ui';
import { toast } from '../../utils/toast';
import { hstack } from '@onbeam/styled-system/patterns';
import { css } from '@onbeam/styled-system/css';

export default function ListUpdatePopup({
  removeAfterMs,
  listUrl,
  oldList,
  newList,
  auto,
}: {
  removeAfterMs: number | null;
  listUrl: string;
  oldList: TokenList;
  newList: TokenList;
  auto: boolean;
}) {
  const dispatch = useDispatch<AppDispatch>();

  const handleAcceptUpdate = useCallback(() => {
    if (auto) return;
    dispatch(acceptListUpdate(listUrl));
  }, [auto, dispatch, listUrl]);

  const {
    added: tokensAdded,
    changed: tokensChanged,
    removed: tokensRemoved,
  } = useMemo(() => {
    return diffTokenLists(oldList.tokens, newList.tokens);
  }, [newList.tokens, oldList.tokens]);
  const numTokensChanged = useMemo(
    () =>
      Object.keys(tokensChanged).reduce(
        (memo, chainId: any) =>
          memo + Object.keys(tokensChanged[chainId]).length,
        0,
      ),
    [tokensChanged],
  );

  useEffect(() => {
    const data = auto ? (
      <p>
        The token list &quot;{oldList.name}&quot; has been updated to{' '}
        <strong>{listVersionLabel(newList.version)}</strong>.
      </p>
    ) : (
      <>
        <div>
          <p>
            An update is available for the token list &quot;{oldList.name}
            &quot; ({listVersionLabel(oldList.version)} to{' '}
            {listVersionLabel(newList.version)}).
          </p>
          <ul>
            {tokensAdded.length > 0 ? (
              <li>
                {tokensAdded.map((token, i) => (
                  <React.Fragment key={`${token.chainId}-${token.address}`}>
                    <strong title={token.address}>{token.symbol}</strong>
                    {i === tokensAdded.length - 1 ? null : ', '}
                  </React.Fragment>
                ))}{' '}
                added
              </li>
            ) : null}
            {tokensRemoved.length > 0 ? (
              <li>
                {tokensRemoved.map((token, i) => (
                  <React.Fragment key={`${token.chainId}-${token.address}`}>
                    <strong title={token.address}>{token.symbol}</strong>
                    {i === tokensRemoved.length - 1 ? null : ', '}
                  </React.Fragment>
                ))}{' '}
                removed
              </li>
            ) : null}
            {numTokensChanged > 0 ? (
              <li>{numTokensChanged} tokens updated</li>
            ) : null}
          </ul>
        </div>
        <div className={hstack({ align: 'center', gap: '2', mt: '2' })}>
          <Button
            size="sm"
            className={css({ w: 'full' })}
            onClick={() => toast.dismiss(toastId)}
          >
            dismiss
          </Button>

          <Button
            size="sm"
            className={css({ w: 'full' })}
            onClick={() => {
              handleAcceptUpdate();
              toast.dismiss(toastId);
            }}
          >
            accept update
          </Button>
        </div>
      </>
    );

    const toastId = toast.success('message', {
      duration: removeAfterMs ?? Number.POSITIVE_INFINITY,
      description: data,
    });
  }, [
    auto,
    handleAcceptUpdate,
    newList.version,
    numTokensChanged,
    oldList.name,
    oldList.version,
    tokensAdded,
    tokensRemoved,
    removeAfterMs,
  ]);

  return null;
}
