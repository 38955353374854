import CurrencyLogo from '../CurrencyLogo';
import { Trade } from '@beamswap/sdk';
import { Fragment, memo } from 'react';
import { Flex } from 'rebass';
import { useAllTokens } from '../../hooks/Tokens';
import { isDefined } from '../../utils/typePresence';
import { ForwardIcon } from '@onbeam/icons';
import { text } from '@onbeam/styled-system/patterns';

export default memo(function SwapRoute({ trade }: { trade: Trade }) {
  const allTokens = useAllTokens();

  const tradePaths = trade.route.path
    .map((token) => allTokens[token.address])
    .filter(isDefined);

  return (
    <Flex
      px="1rem"
      py="0.5rem"
      my="0.5rem"
      style={{ border: '1px solid #40444F', borderRadius: '1rem' }}
      flexWrap="wrap"
      width="100%"
      justifyContent="space-evenly"
      alignItems="center"
    >
      {tradePaths.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1;
        return (
          <Fragment key={`${token.symbol}-${i}`}>
            <Flex my="0.5rem" alignItems="center" style={{ flexShrink: 0 }}>
              <CurrencyLogo currency={token} size="1.5rem" />
              <p className={text({ style: 'md', ml: '2' })}>{token.symbol}</p>
            </Flex>
            {isLastItem ? null : <ForwardIcon />}
          </Fragment>
        );
      })}
    </Flex>
  );
});
