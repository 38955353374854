import { ConnectButton as RKConnectButton } from '@rainbow-me/rainbowkit';
import { Button, ButtonProps } from '@onbeam/ui';
import { css } from '@onbeam/styled-system/css';
import { flex } from '@onbeam/styled-system/patterns';

export const ConnectButton = ({ size = 'sm' }: Pick<ButtonProps, 'size'>) => {
  return (
    <RKConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              className: css({
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              }),
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button onClick={openConnectModal} size={size}>
                    connect
                  </Button>
                );
              }

              if (chain.unsupported) {
                return (
                  <Button onClick={openChainModal} size={size}>
                    wrong network
                  </Button>
                );
              }

              return (
                <div className={flex({ gap: '3' })}>
                  <div className={css({ hideBelow: 'sm' })}>
                    <Button
                      onClick={openChainModal}
                      size={size}
                      {...(chain.hasIcon &&
                        chain.iconUrl && {
                          iconLeft: (
                            <img
                              alt={chain.name ?? 'chain icon'}
                              src={chain.iconUrl}
                              className={css({
                                width: '[12px]',
                                height: '[12px]',
                              })}
                            />
                          ),
                        })}
                    >
                      {chain.name?.toLowerCase()}
                    </Button>
                  </div>
                  <Button onClick={openAccountModal} size={size} hasLed ledOn>
                    {account.ensName ?? account.displayName}
                  </Button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </RKConnectButton.Custom>
  );
};
