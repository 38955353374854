import { BeamConfiguration, ChainId } from '@onbeam/sdk';

const environment = process.env.REACT_APP_BEAM_ENVIRONMENT;

const beamConfig = new BeamConfiguration({
  chains: [
    {
      id: ChainId.BEAM_TESTNET,
      publishableKey: process.env.REACT_APP_BEAM_TESTNET_PUBLISHABLE_KEY ?? '',
      isPreview: environment === 'preview',
    },
    {
      id: ChainId.BEAM_MAINNET,
      publishableKey: process.env.REACT_APP_BEAM_MAINNET_PUBLISHABLE_KEY ?? '',
    },
  ],
  debug: environment === 'preview',
});

export { beamConfig };
