import { ErrorIcon, SuccessIcon } from '@onbeam/icons';
import { Notification } from '@onbeam/ui';
import { ReactElement, ReactNode } from 'react';
import { toast as sonnerToast } from 'sonner';

type ToastData = {
  description?: ReactNode;
  icon?: ReactElement;
  duration?: number;
};

// If you want to use the toast on page load, then you have to wrap the toast in a setTimeout function
// (https://sonner.emilkowal.ski/toast#render-toast-on-page-load)
const messageToast = (message: string, data?: ToastData) =>
  sonnerToast.custom(
    (id) => (
      <Notification
        icon={data?.icon}
        title={message}
        description={data?.description}
        onClose={() => sonnerToast.dismiss(id)}
      />
    ),
    { duration: data?.duration },
  );

const successToast = (message: string, data?: Omit<ToastData, 'icon'>) =>
  messageToast(message, { ...data, icon: <SuccessIcon /> });

const errorToast = (message: string, data?: Omit<ToastData, 'icon'>) =>
  messageToast(message, { ...data, icon: <ErrorIcon /> });

export const toast = {
  message: messageToast,
  success: successToast,
  error: errorToast,
  dismiss: (toastId?: number | string) => sonnerToast.dismiss(toastId),
};
