import { NetworkConnector } from './NetworkConnector';
import { Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';

export const NETWORK_CHAIN_ID: number = Number.parseInt(
  process.env.REACT_APP_CHAIN_ID ?? '4337',
);

if (typeof NETWORK_CHAIN_ID === 'undefined') {
  throw new Error('NETWORK_CHAIN_ID must be a defined environment variable');
}

export const network = new NetworkConnector({
  urls: {
    '4337': 'https://build.onbeam.com/rpc',
    '13337': 'https://build.onbeam.com/rpc/testnet',
  },
  defaultChainId: NETWORK_CHAIN_ID,
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any);
  return networkLibrary;
}

export const injected = new InjectedConnector({
  supportedChainIds: [4337, 13337],
});
