import { TopNav } from '@onbeam/ui';
import { navItems } from './Header.data';
import { useLocation } from 'react-router-dom';
import { BeamLink } from '../../components/BeamLink';
import { ConnectButton } from '../../components/ConnectButton';
import { Settings } from '../Settings';

export const Header = () => {
  const { pathname } = useLocation();

  return (
    <TopNav
      title="swap"
      activePath={pathname}
      isNavigationItemActive={(item) => item.href === pathname}
      navItems={navItems}
      linkComponent={BeamLink}
    >
      <ConnectButton />
      <Settings />
    </TopNav>
  );
};
