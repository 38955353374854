import Web3ReactManager from '../components/Web3ReactManager';
import { AppDispatch, AppState } from '../state';
import { acceptListUpdate } from '../state/lists/actions';
import { useSelectedListUrl } from '../state/lists/hooks';
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader';
import { FathomScript } from '../utils/FathomScript';
import AddLiquidity from './AddLiquidity';
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './AddLiquidity/redirects';
import Pool from './Pool';
import PoolFinder from './PoolFinder';
import RemoveLiquidity from './RemoveLiquidity';
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects';
import Swap from './Swap';
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects';
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { css, cx } from '@onbeam/styled-system/css';
import { AppBackground, Card } from '@onbeam/ui';
import { vstack } from '@onbeam/styled-system/patterns';
import Popups from '../components/Popups';
import { Footer } from '../layout/Footer';
import { Header } from '../layout/Header';
import '../styles/globals.css';
import '../styles/index.css';
import { customScrollbar } from '@onbeam/styled-system/recipes';

const App = () => {
  // handle token list update if necessary
  const dispatch = useDispatch<AppDispatch>();
  const listsByUrl = useSelector<AppState, AppState['lists']['byUrl']>(
    (state) => state.lists.byUrl,
  );
  const selectedListUrl = useSelectedListUrl();

  useEffect(() => {
    if (selectedListUrl) {
      const { pendingUpdate: pending } = listsByUrl[selectedListUrl];

      if (pending) {
        try {
          dispatch(acceptListUpdate(selectedListUrl));
        } catch (_e) {
          /* */
        }
      }
    }
  }, [selectedListUrl, listsByUrl, dispatch]);

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <FathomScript />
        <Route component={DarkModeQueryParamReader} />
        <div
          className={cx(
            customScrollbar(),
            vstack({
              minH: '[100vh]',
            }),
          )}
        >
          <AppBackground />
          <Header />
          <Popups />
          <main
            className={vstack({
              justify: 'center',
              align: 'center',
              gap: '2',
              flex: '1',
              py: '12',

              md: {
                py: '28',
              },
            })}
          >
            <Card size="lg" className={css({ w: 'full' })}>
              <Web3ReactManager>
                <Switch>
                  <Route exact strict path="/swap" component={Swap} />
                  <Route
                    exact
                    strict
                    path="/swap/:outputCurrency"
                    component={RedirectToSwap}
                  />
                  <Route
                    exact
                    strict
                    path="/send"
                    component={RedirectPathToSwapOnly}
                  />
                  <Route exact strict path="/find" component={PoolFinder} />
                  <Route exact strict path="/pool" component={Pool} />
                  <Route
                    exact
                    strict
                    path="/create"
                    component={RedirectToAddLiquidity}
                  />
                  <Route exact path="/add" component={AddLiquidity} />
                  <Route
                    exact
                    path="/add/:currencyIdA"
                    component={RedirectOldAddLiquidityPathStructure}
                  />
                  <Route
                    exact
                    path="/add/:currencyIdA/:currencyIdB"
                    component={RedirectDuplicateTokenIds}
                  />
                  <Route
                    exact
                    strict
                    path="/remove/:tokens"
                    component={RedirectOldRemoveLiquidityPathStructure}
                  />
                  <Route
                    exact
                    strict
                    path="/remove/:currencyIdA/:currencyIdB"
                    component={RemoveLiquidity}
                  />
                  <Route component={RedirectPathToSwapOnly} />
                </Switch>
              </Web3ReactManager>
            </Card>
          </main>
          <Footer />
        </div>
      </HashRouter>
    </Suspense>
  );
};

export default App;
